.categories-list
    h2
        font-size: 1rem
        text-transform: uppercase
        border-bottom: 2px solid
        margin: 0
        padding-bottom: 0.5rem

    ul
        list-style-type: none
        margin: 0 0 1rem
        padding: 0

    li
        display: flex
        align-items: center
        column-gap: ($gutter/2)
        border-bottom: 1px solid
        padding: 0.5rem 0

    .actions
        margin-left: auto

.add-category-btn
    background: white
    color: var(--accent-color)
    border-color: white
    width: 100%
    &:hover
        color: white

.categories-form
    display: flex
    width: 100%

    .form-field
        margin: 0
        flex: 1 1 auto
        position: relative
    .form-error
        position: absolute
        top: 100%
        left: 0
        right: 0

    input
        border-color: var(--text-color)
        height: 100%
        +input-placeholder
            color: var(--text-color)

    .btn
        padding: 0 10px
        height: auto

        svg
            width: 1.4rem
            height: 1.4rem

        &.cancel-btn
            background: transparent
            border-color: var(--text-color)
            color: var(--text-color)
            border-top-right-radius: 0
            border-bottom-right-radius: 0
            border-right: none
            &:hover
                background: var(--text-color)
                color: var(--settings-bg)

        &.add-btn
            background: var(--text-color)
            color: white
            border-color: var(--text-color)
            border-top-left-radius: 0
            border-bottom-left-radius: 0
            border-left: none
            &:hover
                background: white
                color: var(--text-color)

