@import-normalize
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap')

@import "_variables"
@import "_helpers"
@import "_base"
@import "_forms"

@import "_header"
@import "_form-modal"
@import "_welcome"
@import "_dashboard"
@import "_settings"
@import "_movements"
@import "_totals"
@import "_categories"