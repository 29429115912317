.welcome-main
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background: var(--accent-color)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: $gutter

.welcome-main--header
    text-align: center
    margin: 1rem 0 2rem
    h1
        margin: 0
        font-size: 2.5rem

.welcome-main--content
    background: white
    width: 100%
    max-width: 300px
    padding: $gutter

    .form-field
        margin-bottom: 0.5rem
    .form-submit
        margin-top: 1rem
        .btn
            width: 100%

