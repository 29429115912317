*, *:before, *:after
    box-sizing: border-box

* 
    margin: 0

html
    font-size: 16px
    max-width: 100vw
    
body
    background-color: var(--body-bg)
    color: var(--text-color)
    font-family: var(--font-family-default)
    font-size: 1rem
    line-height: 1.4

section, article, aside, header, footer, nav, main, figure, figcaption, code, picture
    display: block

h1, h2, h3, h4, h5, h6, p, ul, ol, dl
    margin: 0 0 1rem

img
    max-width: 100%
    height: auto


// ----------------------------------------------------
// TYPOGRAPHY

em, i
    font-style: italic
strong, b
    font-weight: bold
strong em, em strong, b i, i b
    font-style: italic
    font-weight: bold
u
    text-decoration: underline

h1, h2, h3, h4, h5, h6
    font-weight: bold

h1
    font-size: 2rem
h2
    font-size: 1.5rem
h3
    font-size: 1.3rem
h4
    font-size: 1.1rem
h5
    font-size: 1rem
h6
    font-size: 0.8rem

small
    font-size: 0.8rem

// ----------------------------------------------------
// LINKS & BUTTONS

a:focus, input:focus, textarea:focus, button:focus, .slick-slide:focus
    outline: none

a, button, .btn
    transition: all 0.3s

a
    -webkit-tap-highlight-color: transparent //ios fix
    color: var(--accent-color)
    text-decoration: underline
    &:hover
        color: var(--text-color)

input[type="submit"], button, .btn
    -webkit-appearance: none
    display: inline-flex
    align-items: center
    justify-content: center
    column-gap: 5px
    cursor: pointer
    font-size: 0.9rem
    font-weight: bold
    line-height: 1
    text-align: center
    border-radius: 5px
    border: 2px solid map-get($btns-colors, default)
    background: map-get($btns-colors, default)
    color: white
    padding: 10px 15px
    height: 38px
    text-transform: uppercase
    text-decoration: none
    &:hover
        background: transparent
        color: map-get($btns-colors, default)

    @each $name,$color in $btns-colors
        &.btn-#{$name}
            background: $color
            border-color: $color
            &:hover
                background: transparent
                color: $color

    svg
        width: 1.4em
        height: 1.4em


.unstyled-btn
    padding: 0
    background: transparent
    border: none
    height: auto


// ----------------------------------------------------
// ACTIONS BTNS

.actions
    display: flex
    align-items: center
    column-gap: 5px
    .btn
        padding: 0
        width: 1.6rem
        height: 1.6rem
        background: var(--text-color)
        border-color: var(--text-color)
        svg
            width: 1rem
            height: 1rem
        &:hover
            background: transparent
            color: var(--text-color)