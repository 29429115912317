.form-modal
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(0,0,0,0.75)
    display: flex
    align-items: center
    justify-content: center
    padding: $gutter
    z-index: 10000

.form-modal--wrapper
    padding: $gutter
    background: white
    width: 100%
    max-width: 600px

.form-modal--header
    display: flex
    align-items: center
    margin-bottom: 2rem
    h2
        margin: 0

    .close-modal
        margin-left: auto
        padding: 0
        width: $nav-actions-btn-size
        height: $nav-actions-btn-size
        background: transparent
        color: var(--text-color)
        border-radius: 50%
        border-color: var(--text-color)
        &:hover
            background: var(--text-color)
            color: white

        svg
            width: $nav-actions-btn-icon-size
            height: auto
