
    
// ----------------------------------------------------
// FORMS

label
    display: block
    max-width: 100% // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
    color: var(--color-gray)
    text-transform: uppercase
    font-weight: bold
    font-size: 0.9rem

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="file"]
    box-sizing: border-box
    display: block
    font-family: var(--font-family-default)
    font-size: 1rem
    line-height: 1
    box-shadow: none
    display: flex
    align-items: center
    background-image: none
    width: 100%
    background: transparent
    color: var(--text-color)
    border: 2px solid var(--color-gray-light)
    padding: 8px 10px

    &:focus
        outline: none

    // Disabled and read-only inputs
    &[disabled],
    &[readonly],
    fieldset[disabled] &
        opacity: 1 // iOS fix for unreadable disabled content see https://github.com/twbs/bootstrap/issues/11655
    &[disabled],
    fieldset[disabled] &
        cursor: not-allowed

    +input-placeholder
        color: var(--color-gray)

input[type="file"]
    padding-top: 9px

// select
//     background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
//     background-position: calc(100% - #{$input-padding-x} - 5px) 50%, calc(100% - #{$input-padding-x - 1}) 50%
//     background-size: 6px 6px, 6px 6px
//     background-repeat: no-repeat
//     -moz-appearance: none
//     -webkit-appearance: none
//     appearance: none
//     cursor: pointer
//     // Unstyle the caret on `<select>`s in IE10+.
//     &::-ms-expand
//         border: 0
//         background-color: transparent


textarea
    height: auto
    line-height: 1.4
    min-height: 200px


// ----------------------------------------------------
// ERRORS

.field-error
    input, select, textarea
        border-color: var(--color-error)

.form-error
    color: var(--color-error)
    font-size: 0.85rem
    margin-top: 0.25rem
    font-weight: bold

// ----------------------------------------------------
// WRAPPERS

.form-field
    margin-bottom: 1rem

.form-submit
    margin-top: 2rem
    display: flex
    column-gap: ($gutter/2)
    align-items: center
    justify-content: flex-end
    .btn:not(.btn-primary)
        margin-right: auto