:root {
    --font-family-default: 'Lato', sans-serif;

    --color-yellow: #FCB93D;
    --color-black: #3C3C3B;
    --color-gray: #7F7F7F;
    --color-gray-light: #cecaca;
    --color-gray-lightest: #F2F2F2;

    --color-success: #34C562;
    --color-error: #e33e3c;
    --color-warning: #F3BE2F;
    --color-info: #23ADF4;

    --accent-color: var(--color-yellow);
    --body-bg: white;
    --text-color: var(--color-black);

    --btn-color-primary: var(--accent-color);
    --btn-color-secondary: var(--accent-color);

    --income-color: var(--color-success);
    --expense-color: var(--color-error);

    --header-bg: var(--color-black);

    --settings-bg: var(--accent-color);

    --total-positive-color: var(--color-success);
    --total-negative-color: var(--color-error);
}

$btns-colors: (
    'primary': var(--accent-color),
    'default': var(--color-gray),
    'danger': var(--color-error)
);

$alert-colors: (
    'default': var(--color-gray),
    'success': var(--color-success),
    'danger': var(--color-danger),
    'warning': var(--color-warning),
    'info': var(--color-info),
);

$gutter: 20px;
$grid-columns: 12;

$nav-actions-btn-size: 2.4rem;
$nav-actions-btn-icon-size: 1.7rem;

$dashboard-aside-width: 360px;
$dashboard-breakpoint: 'sm';
$dashboard-breakpoint-max: 'xs-max';

$settings-panel-width: 360px;
$settings-panel-trans: 0.35s ease;