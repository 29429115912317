.totals
    +breakpoint($dashboard-breakpoint-max)
        width: span(1,2)
    +breakpoint($dashboard-breakpoint)
        margin-bottom: 2rem
        &:last-child
            margin-bottom: 0
    h3
        text-transform: uppercase
        font-size: 1rem
        border-bottom: 2px solid
        margin-bottom: 0
        padding-bottom: 0.5rem

    &.year-totals
        +breakpoint($dashboard-breakpoint)
            margin-top: auto

.totals-item
    display: flex
    align-items: center
    flex-wrap: wrap
    column-gap: $gutter
    border-bottom: 1px solid
    padding: 5px 0
    .title
        font-size: 1rem
        margin: 0
    .description
        margin-left: auto
        font-size: 1.2rem

    &.highlight
        .description
            font-weight: bold
        &.positive .description
            color: var(--total-positive-color)
        &.negative .description
            color: var(--total-negative-color)

.totals-extras
    width: 100%
    list-style-type: none
    margin: 0
    padding: 0

    li
        display: flex
        align-items: center
        &::before
            flex: 0 0 auto
            content: '\2022'
            margin-right: 6px
    .name
        margin-right: $gutter
    .value
        flex: 0 0 auto
        margin-left: auto