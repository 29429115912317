$header-height: 70px
    
.app-header
    height: $header-height
    position: fixed
    top: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    padding: 0 $gutter
    background: var(--header-bg)
    z-index: 50

body
    margin-top: $header-height


// ACTIONS

.app-header--actions
    display: flex
    align-items: center
    margin-left: auto
    column-gap: ($gutter/2)

    .btn
        padding: 0
        width: $nav-actions-btn-size
        height: $nav-actions-btn-size
        background: var(--color-gray-lightest)
        color: var(--header-bg)
        border-radius: 50%
        border: none
        &:hover
            background: var(--accent-color)
            color: white

        svg
            width: $nav-actions-btn-icon-size
            height: auto


// MONTH NAV

.month-nav
    display: flex
    align-items: stretch

.month-nav--current
    border: none
    background: var(--accent-color)
    color: white
    margin-right: 5px
    height: $nav-actions-btn-size
    padding: 0 10px
    &:hover
        background: white
        color: var(--header-bg)
    svg
        width: $nav-actions-btn-icon-size
        height: $nav-actions-btn-icon-size

.month-nav--prev,
.month-nav--next
    background: var(--color-gray)
    color: var(--color-gray-lightest)
    border: none
    height: $nav-actions-btn-size
    padding: 0 5px
    &:hover
        background: var(--color-gray)
        color: var(--color-gray-lightest)
        opacity: 0.5
    svg
        width: 1.2rem
        height: 1.2rem

.month-nav--prev
    border-top-right-radius: 0
    border-bottom-right-radius: 0
.month-nav--next
    border-top-left-radius: 0
    border-bottom-left-radius: 0

.month-nav--selected
    display: flex
    align-items: center
    justify-content: center
    text-transform: uppercase
    font-size: 1.2rem
    background: var(--color-gray)
    color: var(--color-gray-lightest)
    width: 8rem
    border-left: 1px solid var(--header-bg)
    border-right: 1px solid var(--header-bg)
    