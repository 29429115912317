.settings-panel
    box-shadow: none
    transition: right $settings-panel-trans, box-shadow 0.5s
    background: var(--settings-bg)
    position: fixed
    top: 0
    right: -($settings-panel-width)
    bottom: 0
    width: $settings-panel-width
    z-index: 10
    padding: $gutter
    &.opened
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5)
        right: 0

.dashboard, .app-header
    transition: margin-left $settings-panel-trans, margin-right $settings-panel-trans
.dashboard-aside
    transition: margin-right $settings-panel-trans
.settings-opened,
.settings-opened .dashboard-aside
    margin-right: $settings-panel-width
    margin-left: -($settings-panel-width)

.settings-header
    display: flex
    align-items: center
    margin-bottom: 2rem
    h2
        margin: 0

    .settings-btn
        margin-left: auto
        padding: 0
        width: $nav-actions-btn-size
        height: $nav-actions-btn-size
        background: transparent
        color: var(--text-color)
        border-radius: 50%
        border-color: var(--text-color)
        &:hover
            background: var(--text-color)
            color: var(--settings-bg)

        svg
            width: $nav-actions-btn-icon-size
            height: auto
