.income-list, .expenses-list
    margin-bottom: 2rem
    &:last-child
        margin-bottom: 0


.movements-header
    display: flex
    align-items: center
    padding-bottom: 1rem
    border-bottom: 2px solid
    h2
        margin: 0
    .btn
        margin-left: auto


// Sticky header
$movements-header-height: 72px // improve this
.expenses-list
    position: relative
    padding-top: $movements-header-height
    .movements-header
        position: absolute
        top: 0
        left: 0
        right: 0
        padding-top: 1rem
        background: white
        height: $movements-header-height
        &.fixed
            position: fixed
            top: $header-height
            left: $gutter
            right: ($dashboard-aside-width + $gutter)
            z-index: 10
            +breakpoint($dashboard-breakpoint-max)
                right: $gutter
        

.movements-list
    list-style-type: none
    padding: 0
    margin: 0

.movements-item
    display: flex
    align-items: center
    column-gap: $gutter
    border-bottom: 1px solid
    padding: 5px 0


    .value
        margin-left: auto
        font-size: 1.2rem
        font-weight: bold

    .date
        flex: 0 0 auto
        font-size: 1.2rem
        width: 1.6rem

    .category
        display: block
        color: var(--color-gray)

    .actions .btn
        background: var(--color-gray-light)
        border-color: var(--color-gray-light)
        &:hover
            background: transparent
            color: var(--color-gray-light)