.dashboard-main, .dashboard-aside
    padding: $gutter
    +breakpoint($dashboard-breakpoint)
        padding-bottom: ($gutter*3)

.dashboard-main
    +breakpoint($dashboard-breakpoint)
        margin-right: $dashboard-aside-width
    
.dashboard-aside
    background: var(--color-gray-lightest)
    +breakpoint($dashboard-breakpoint-max)
        display: flex
        column-gap: $gutter

    +breakpoint($dashboard-breakpoint)
        position: fixed
        top: $header-height
        right: 0
        bottom: 0
        width: $dashboard-aside-width
        display: flex
        flex-direction: column
