/* SASS MIXINS AND FUNCTIONS */

@mixin input-placeholder
    &.placeholder
        @content
    &:-moz-placeholder
        @content
    &::-moz-placeholder
        @content
    &:-ms-input-placeholder
        @content
    &::-webkit-input-placeholder
        @content

@mixin position-center($position, $right: 0, $left: 0)
    position: $position
    right: $right
    left: $left
    margin: 0 auto

@mixin background-cover($position: center)
    background-repeat: no-repeat
    background-position: $position
    background-size: cover

// Text selection styles
@mixin text-selection
    ::-moz-selection
        @content
    ::selection
        @content

// Disable text selection
@mixin disable-user-select
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    cursor: default


// MEDIA QUERIES
// Breakpoints: xs, sm, md, lg, xl
// Max Breakpoints: xs-max, sm-max, md-max, lg-max
$screen-xs:                  480px
$screen-sm:                  768px
$screen-md:                  992px
$screen-lg:                  1200px
$screen-xl:                  1920px
$screen-xs-max:              ($screen-sm - 1)
$screen-sm-max:              ($screen-md - 1)
$screen-md-max:              ($screen-lg - 1)
$screen-lg-max:              ($screen-xl - 1)

@mixin breakpoint($point)
    @if $point == 'xs'
        @media (max-width: $screen-sm - 1)
            @content
    @else if $point == 'sm'
        @media (min-width: $screen-sm)
            @content
    @else if $point == 'md'
        @media (min-width: $screen-md)
            @content
    @else if $point == 'lg'
        @media (min-width: $screen-lg)
            @content
    @else if $point == 'xl'
        @media (min-width: $screen-xl)
            @content
    @else if $point == 'xs-max'
        @media (max-width: $screen-xs-max)
            @content
    @else if $point == 'sm-max'
        @media (max-width: $screen-sm-max)
            @content
    @else if $point == 'md-max'
        @media (max-width: $screen-md-max)
            @content
    @else if $point == 'lg-max'
        @media (max-width: $screen-lg-max)
            @content
    @else
        @media (min-width: $point)
            @content
@mixin retina()
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
        @content

// TRIANGLES
// directions: bottom, top, left, right
@mixin triangle($direction, $color, $width, $height)
    content: ""
    display: inline-block
    zoom: 1
    width: 0
    height: 0

    @if $direction == bottom or $direction == top
        border-right: $width solid transparent
        border-left: $width solid transparent
        @if $direction == bottom
            border-top: $height solid $color
            border-bottom: none
        @else
            border-bottom: $height solid $color
            border-top: none
    @else if $direction == left or $direction == right
        border-top: $height solid transparent
        border-bottom: $height solid transparent
        @if $direction == left
            border-right: $width solid $color
            border-left: none
        @else
            border-left: $width solid $color
            border-right: none

    
// GRID
@function span($cols, $grid-columns: $grid-columns)
    @return ((100%/$grid-columns) * $cols)

@mixin span($cols, $grid-columns: $grid-columns)
    width: span($cols, $grid-columns)

@mixin row($clearfix:false, $gap: $gutter)
    display: flex
    column-gap: $gap